<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "roles",
              "add-permission-to-role",
              "add-permission-to-role"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('roles', 'add-permission-to-role', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="clebex-item-section" v-if="permissions && permissions.length">
      <li
        class="clebex-item-section-item"
        v-for="permission in permissions"
        :key="permission.id"
      >
        <div class="clebex-item-content-wrapper">
          <span class="lead-icon align-start">
            <div class="checkbox slide">
              <input
                :id="`permissionCheckbox${permission.id}`"
                type="checkbox"
                :checked="
                  selectedPermissions &&
                    selectedPermissions.filter(item =>
                      item.permission_id
                        ? item.permission_id === permission.id
                        : item.id === permission.id
                    ).length
                "
                @change="setSelectedPermission(permission)"
              />
              <label :for="`permissionCheckbox${permission.id}`"></label>
            </div>
          </span>
          <div
            class="clebex-section-input"
            v-if="permission.permission_type && permission.permission_type.type"
          >
            <p class="clebex-section-input-label">{{ permission.name }}</p>
            <template v-if="permission.permission_type.type === 'CRUD'">
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-create-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission.create"
                />
                <label :for="`permission-create-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "create"
                    )
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-read-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission['read']"
                />
                <label :for="`permission-read-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName("roles", "add-permission-to-role", "read")
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-update-${permission.id}`"
                  v-model="permission.update"
                  @change="updateSelectedPermission(permission)"
                />
                <label :for="`permission-update-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "update"
                    )
                  }}</label
                >
              </div>
              <div class="checkbox tiny alt gray-label no-background">
                <input
                  type="checkbox"
                  :id="`permission-delete-${permission.id}`"
                  @change="updateSelectedPermission(permission)"
                  v-model="permission.delete"
                />
                <label :for="`permission-delete-${permission.id}`">
                  <icon icon="#cx-app1-checkmark" />
                  {{
                    displayLabelName(
                      "roles",
                      "add-permission-to-role",
                      "delete"
                    )
                  }}</label
                >
              </div>
            </template>
            <template v-if="permission.permission_type.type === 'NUMERICAL'">
              <input
                type="text"
                v-model="permission.value"
                @change="updateSelectedPermission(permission)"
                :placeholder="
                  displayLabelName(
                    'roles',
                    'add-permission-to-role',
                    'enter-value'
                  )
                "
              />
            </template>
          </div>
        </div>
        <!--        <button-->
        <!--          class="clebex-item-content-wrapper"-->
        <!--          @click="setSelectedPermission(permission)"-->
        <!--        >-->
        <!--          <span class="label">{{ permission.name }}</span>-->
        <!--          <span class="follow-up-icons">-->
        <!--            <span-->
        <!--              class="follow-up-icon-item"-->
        <!--              v-if="-->
        <!--                selectedPermissions &&-->
        <!--                  selectedPermissions.filter(item => item.id === permission.id)-->
        <!--                    .length-->
        <!--              "-->
        <!--            >-->
        <!--              <icon icon="#cx-app1-checkmark"></icon>-->
        <!--            </span>-->
        <!--            <span class="follow-up-icon-item" v-else>-->
        <!--              <icon-->
        <!--                icon="#cx-app1-arrow-right-12x12"-->
        <!--                width="12"-->
        <!--                height="12"-->
        <!--              ></icon>-->
        <!--            </span>-->
        <!--          </span>-->
        <!--        </button>-->
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
export default {
  name: "RolesAddPermissions",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  computed: {
    ...mapState("containers", ["containerAccessTypes"]),
    ...mapState("roles", [
      "permissionsList",
      "selectedPermissions",
      "permissionsInRole"
    ]),
    containerTypeId() {
      const { containerAccessTypes, containerType } = this;
      if (
        containerType &&
        containerAccessTypes &&
        containerAccessTypes.data &&
        containerAccessTypes.data.length
      ) {
        const filtered = containerAccessTypes.data.filter(
          item => item.type === containerType
        );
        if (filtered && filtered.length) {
          return filtered[0].id;
        }
        return null;
      }
      return null;
    },
    permissions() {
      const query = this.searchQuery;
      if (
        this.permissionsList &&
        this.permissionsList.data &&
        this.permissionsList.data.length
      ) {
        if (query && query.length > 1) {
          return this.permissionsList.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.permissionsList.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("roles", [
      "getPermissionsList",
      "setSelectedPermission",
      "updateSelectedPermission"
    ])
  },
  watch: {
    containerTypeId: {
      immediate: true,
      handler() {
        if (this.containerTypeId) {
          this.getPermissionsList({
            access_type_ids: [this.containerTypeId],
            includes: ["permission_type", "permission_category"]
          });
        }
      }
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    },
    containerType: {
      required: true,
      type: String
    }
  }
};
</script>
